// main.js is used as a manifest for loading the scripts which control component behavior. 



//-- LOAD EXTERNAL DEPENDENCIES --\\

// Load any external dependencies along with Bootstrap components here...
// These dependencies can also be loaded via npm or from the ./js/lib/ directory
import Alert from 'bootstrap/js/dist/alert';
import BaseComponent from 'bootstrap/js/dist/base-component';
import Button from 'bootstrap/js/dist/button';
import Carousel from 'bootstrap/js/dist/carousel';
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Popover from 'bootstrap/js/dist/popover';
import ScrollSpy from 'bootstrap/js/dist/scrollspy';
import Tab from 'bootstrap/js/dist/tab';
import Toast from 'bootstrap/js/dist/toast';
import Tooltip from 'bootstrap/js/dist/tooltip';



//--LOAD CUSTOM COMPONENTS--\\
import header from "./components/header";
import sliderBanner from "./components/slider_banner";
import alertBanner from "./components/alert_banner";
import mediaCallout from "./components/media_callout";
import insightsCarousel from "./components/insights-carousel";
import locations from "./components/locations";
import statsBanner from "./components/stats_banner";

// Load your theme's custom components here, individually, for each component. 
// Example:
// import 'carousel' from './components/carousel.js'
// import 'accordion' from './components/accordion.js'
