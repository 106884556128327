$(document).ready(function () {
  if ($(".insights-carousel").hasClass("three-items")) {
    $(".insights-carousel.three-items .insights-container").slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  } 
  if ($(".insights-carousel").hasClass("two-items")) {
    console.log($(".insights-carousel.two-items .insights-container"));
    $(".insights-carousel.two-items .insights-container").slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
});
