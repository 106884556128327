$(document).ready(function () {
    const mediaCallout = document.querySelector(".media-callout.video");
    if (mediaCallout) {
        // Gets the video src from the data-src on each button
        var videoSrc;

        $(".video-btn").click(function () {
            $('div[id^="' + $(this).data("target").substring(1) + '"]').modal("show");
            videoSrc = $(this).data("src");
            handleVideoSrc(videoSrc, this); // Call the callback function
        });

        $(".modal .close").click(function () {
            $('div[id^="mediacalloutmodal-"]').modal("hide");
        });

        function handleVideoSrc(videoSrc, element) {
            // when the modal is opened autoplay it
            $('div[id^="' + $(element).data("target").substring(1) + '"]').on(
                "shown.bs.modal",
                function (e) {
                    // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
                    var paramSep = '?';

                    if (videoSrc.indexOf(paramSep) !== -1) {
                        paramSep = '&amp;'
                    }

                    $('div[id^="' + $(element).data("target").substring(1) + '"]')
                        .find("#video")
                        .attr(
                            "src",
                            videoSrc + paramSep + "autoplay=1&amp;modestbranding=1&amp;showinfo=0"
                        );
                }
            );

            // stop playing the youtube video when I close the modal
            $('div[id^="' + $(element).data("target").substring(1) + '"]').on(
                "hide.bs.modal",
                function (e) {
                    // a poor man's stop video
                    $('div[id^="' + $(element).data("target").substring(1) + '"]')
                        .find("#video")
                        .attr("src", "");
                }
            );
        }
    }
});
