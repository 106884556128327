$(document).ready(function () {
  var counters = $(".count");
  var countersQuantity = counters.length;
  var counter = [];
  var animationDuration = 5000; // Set your desired animation duration in milliseconds

  for (var i = 0; i < countersQuantity; i++) {
    // Extract the numeric value from the text without formatting
    var rawValue = counters[i].innerText.match(/[\d.,]+/);
    counter[i] = parseFloat(
      rawValue[0].replace(/[,]/g, "").replace(/[^\d.]/g, "")
    );
  }

  var count = function (start, end, id) {
    var localStart = start;
    var decimalPlaces =
      end % 1 === 0 ? 0 : (end.toString().split(".")[1] || []).length;
    var difference = end - start;
    var increment = difference / (animationDuration / 40);

    var updateCounter = function () {
      if (localStart < end) {
        localStart += increment;
        var formattedValue = localStart
          .toFixed(decimalPlaces)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        counters[id].innerText =
          formattedValue +
          counters[id].innerText
            .replace(/[\d.,]/g, "")
            .replace(/(\d+)[,](\d+)/g, "$1,$2");
        requestAnimationFrame(updateCounter);
      }
    };

    updateCounter();
  };

  for (var j = 0; j < countersQuantity; j++) {
    count(0, counter[j], j);
  }
});
