$(document).ready(function () {
  const locationsComponent = document.querySelector(".locations-component");
  if (locationsComponent) {
    $(document.querySelector(".locations-component .filters-button")).on(
      "click",
      function (event) {
        event.preventDefault();
        $(".locations-component .location").css("display", "none");
        $(".locations-component .filters").css("display", "block");
      }
    );
    $(document.querySelector(".locations-component .fa-xmark")).on(
      "click",
      function (event) {
        event.preventDefault();
        $(".locations-component .location").css("display", "block");
        $(".locations-component .filters").css("display", "none");
      }
    );
    $(
      document.querySelector(".locations-component .filters button.link-btn")
    ).on("click", function (event) {
      event.preventDefault();
      $(".locations-component .location").css("display", "block");
      $(".locations-component .filters").css("display", "none");
    });
    $(document.querySelector(".locations-component .list-view")).on(
      "click",
      function (event) {
        event.preventDefault();
        $(".locations-component .map-view").removeClass("red-bottom-border");
        $(this).addClass("red-bottom-border");
        $(".locations-component .map-full").css("display", "none");
        var containerWidth = $(".locations-component .container").width();
        $(".locations-component .results-list").css("width", containerWidth);
        $(".locations-component .results-list").css("max-height", "unset");
        $(".locations-component .results-list").css("overflow", "unset");
      }
    );
    $(document.querySelector(".locations-component .map-view")).on(
      "click",
      function (event) {
        event.preventDefault();
        $(".locations-component .list-view").removeClass("red-bottom-border");
        $(this).addClass("red-bottom-border");
        $(".locations-component .map-full").css("display", "block");
        $(".locations-component .results-list").css("width", "100%");
        $(".locations-component .results-list").css("max-height", "600px");
        $(".locations-component .results-list").css("overflow", "auto");
      }
    );
  }
});
