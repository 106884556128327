$(document).ready(function () {
	$(".slider-banner").slick({
		dots: true,
		infinite: true,
		speed: 500,
		fade: true,
		arrows: false,
		cssEase: "linear",
		adaptiveHeight: true,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		customPaging: function (slider, i) {
			var $slide = $(slider.$slides[i]);
			var title = $slide.attr('title') || ('Slide ' + (i + 1));

			return $('<button type="button" />').text(title).attr('title', title);
		}
	});
});
