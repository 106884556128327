$(document).ready(function () {
	// Function to handle the click event on the desktop
	function handleDesktopClick(event) {
		var windowWidth = $(window).width();
		if (windowWidth >= 768) {
			// Adjust this breakpoint as needed for your design
			var href = $(event.target).attr("href");
			if (href) {
				window.location.href = href;
			}
		}
	}

	// Attach the click event handler to the primary dropdown links
	$(".nav-item.dropdown .nav-link.d-none.d-lg-flex").on(
		"click",
		handleDesktopClick
	);
});
